import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import _, { concat } from "lodash";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import "./style.scss";
import { creategeneric } from "../../../service/apiServices.js";
import "./radiotest.css";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SorryCharlie = ({ campData, ...props }) => {
  const userId = window.localStorage.getItem("userId");
  const sorryreason = window.localStorage.getItem("sorrycharlie");
  const [didLanding, setDidLanding] = useState(false);

  const { mutate: createGenericOnLanding, isLoading: isLoadingGeneric } =
    useMutation(creategeneric, {
      onSuccess: (result) => {},
      onError: () => {
        console.log("error on landing");
      },
    });

  React.useEffect(() => {
    try {
      if (!didLanding) {
        createGenericOnLanding({
          section: "Sorry Charlie - Enter",
          userID: userId,
          campaignID: campData.id,
        });
        setDidLanding(true);
      }
    } catch (error) {
      //console.log(error);
    }
  }, [campData]);

  return (
    <>
      <Row>
        <Col>
          <div className="text-center redemption-text mb-4">
            <FontAwesomeIcon icon={faTriangleExclamation} size="5x" />
            <p className="fivetimespurple">
              <b>Sorry!</b>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center redemption-text mb-4">
            {sorryreason === "Age" && (
              <p>
                <b>You must be 21 or older to participate.</b>
              </p>
            )}

            {sorryreason === "State" && (
              <p>
                <b>
                  This promotion is not happening in your state at this time.
                </b>
              </p>
            )}

            {sorryreason === "" && (
              <p>
                <b>Something has gone wrong. Please try again.</b>
              </p>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default SorryCharlie;
