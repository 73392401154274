const axios = require("axios").default;
// i want to return all axios promises here, no awaiting or error handling here
// my service PROVIDES data, not handles it.


const API_URL = process.env.REACT_APP_API_BASE_URL;
export const createInitialPage = (data) => {
  return axios.post(`${API_URL}/pe/capture-initial-entry/`, data).then((response)=> response.data);
}

export const createSecondaryPage = (data) => {
  return axios.post(`${API_URL}/pe/capture-secondary-entry/`, data).then((response)=> response.data);
}

export const createTrackingLandingPage = (data) => {
  return axios
    .post(`${API_URL}/pe/create-tracking-landing-page/`, data)
    .then((response) => {
      return response.data;
    });
};

export const createTrackingAgeGate = (data) => {
  return axios
    .post(`${API_URL}/pe/create-tracking-age-gate/`, data)
    .then((response) => {
      return response.data;
    });
}

export const createTrackingCashDisbursement = (data) => {
  return axios
    .post(`${API_URL}/pe/create-cash-distribution/`, data)
    .then((response) => {
      return response.data;
    });
}


//'https://localhost:7104/api/Campaign/1/campaigns/7041F6D7-9B98-445C-84CF-FEADEDB7886D' \
export const getCampaignInformation = (id) => {

  return axios
    .get(`${API_URL}/pe/1/campaigns/${id}`)
    .then((response) => {
      return response.data;
    });
  }



export const createUserInfo = (data) => {
  return axios
    .post(`${API_URL}/pe/create-user-info/`, data)
    .then((response) => {
      return response.data;
    });
  }

export const getCampaignByTriggerId = (triggerId) => {
  return axios.get(`${API_URL}/pe/trigger/${triggerId}`).then((resp) => {
    
    return resp;
  })
}



export const createReceipt = (data) => {
  return axios
    .post(`${API_URL}/pe/create-receipt/`, data)
    .then((response) => {
      return response.data;
    });
}


export const creategeneric = (data) => {
  return axios
    .post(`${API_URL}/pe/create-generic-landing-page/`, data)
    .then((response) => {
      return response.data;
    });
}