import React, { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SorryCharlie from "./pages/redemption/known-entity/Sorry";
import 'react-calendar/dist/Calendar.css';
import "./App.css";

const UnknownBase = lazy(() =>
  import("./pages/redemption/unknown-entity/Base")
);

function App() {

  return (
    <BrowserRouter>
      <Suspense fallback={<p> Loading...</p>}>
        <Routes>
          <Route path="/" element={<SorryCharlie />} />
          <Route path="/register/:triggerId" element={<UnknownBase />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
